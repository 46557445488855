<template>
    <div>
        <v-card v-if="trip" class="pa-5 paragraph--text rounded-lg">
            <v-row no-gutters justify="space-between" align="center">
                <h3 class="text-h5 text-bold">{{trip.name}}</h3>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="updateDialog = true">
                            <v-list-item-icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item  @click="$emit('delete', trip.id)">
                            <v-list-item-icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-row>
            <p class="mt-3 mb-1 text-h6 pl-0 text-truncate d-block">{{trip.description}}</p>
            <p class="mb-3 text-h6 pl-0 text-truncate d-block">{{trip.number_of_travellers}} Confirmed traveller</p>
            <div v-if="trip.trip_stops.length">
                <div v-for="(item, i) in trip.trip_stops" :key="item.id">
                    <p class="body-2 mb-1">
                        <v-icon v-if="item.stop_travel_by_id && transportations.length">{{transportations.find(trans => trans.id === item.stop_travel_by_id).slug}}</v-icon>
                        <v-icon v-else>mdi-map-marker</v-icon>
                        {{item.location_label}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <small v-bind="attrs" v-on="on" class="caption text-truncate d-block mx-5">{{item.formatted_address}}</small>
                            </template>
                            <span>{{item.formatted_address}}</span>
                        </v-tooltip>
                    </p>
                    <v-row no-gutters class="my-3" justify="space-between" align="center">
                        <div class="caption">
                            <v-icon>mdi-calendar</v-icon>
                            From: {{new Date(item.stop_from_date.split(' ')[0]).toDateString()}}
                        </div>
                        <div class="caption" v-if="item.stop_to_date">
                            <v-icon>mdi-calendar</v-icon>
                            To: {{new Date(item.stop_to_date.split(' ')[0]).toDateString()}}
                        </div>
                    </v-row>
                    <v-divider v-if="i < trip.trip_stops.length - 1" class="my-3"></v-divider>
                </div>
            </div>
            <div class="d-flex justify-space-between">
                <v-btn @click="$router.push({ path: `/plan-trip/plan-view/${trip.id}` })" color="primary" elevation="0" class="rounded-lg mt-5">trip details</v-btn>
                <v-btn v-if="trip.number_of_travellers" @click="$router.push({ path: `/quickPlanner/booking/${trip.id}` })" color="primary" elevation="0" class="rounded-lg mt-5">Booking</v-btn>
            </div>
        </v-card>

        <!-- update dialog -->
        <v-dialog width="80%" v-model="updateDialog">
            <v-card class="pb-5 pr-5">
                <v-form v-model="valid" ref="tripPost">
                    <postTrip :data="trip" :readonly="false" @setTitle="setTitle" @setDesc="setDesc" />
                    <v-row no-gutters justify="center">
                        <v-spacer></v-spacer>
                        <v-btn @click="updatePlanBsics" :disabled="!planBasicInfo.name || !planBasicInfo.description" class="px-15" color="primary white--text" x-large elevation="0">Update</v-btn>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import postTrip from '@/components/plan_trip/post'

export default {
  props: ['trip', 'transportations'],
  components: { postTrip },
  data () {
    return {
      updateDialog: false,
      planBasicInfo: {
        name: '',
        description: ''
      },
      valid: true
    }
  },
  methods: {
    setTitle (title) {
      this.planBasicInfo.name = title
    },
    setDesc (desc) {
      this.planBasicInfo.description = desc
    },
    updatePlanBsics () {
      this.$refs.tripPost.validate()
      if (this.valid) {
        this.$emit('update', this.planBasicInfo, this.trip.id)
        this.updateDialog = false
      }
    }
  },
  mounted () {
    this.planBasicInfo = {
      name: this.trip.name,
      description: this.trip.description
    }
  }
}
</script>
